import { getCookie } from '~/mixins/cookie'
// import { errorLogger } from '~/mixins/loggerHandle'

// const errorHandle = async (type = 'error', data:any) => {
//     if (!window.IS_BOT) {
//         const durationInMs = new Date().getTime() - data?.config?.meta?.requestStartedAt
//
//         // if(((durationInMs > 3000) && (type === 'response')) || (type === 'error')) {
//         if(type === 'error') {
//             const configAPI = {
//                 baseURL: data.config?.baseURL,
//                 url: data.config?.url,
//                 method: data.config?.method,
//                 header: data.config?.headers,
//                 params: data.config?.params || undefined,
//                 request_data: data.config?.data || undefined
//             }
//
//             const error_message = (type === 'error') ? (data?.response?.data?.message || data?.message) : 'Slow response (longer than 3s)'
//
//             const e_info = {
//                 message: error_message || undefined,
//                 status_code: data?.response?.data?.statusCode || undefined,
//                 response_time: durationInMs + ' ms' || undefined,
//                 api_config: configAPI || undefined,
//                 description: data?.stack || undefined,
//                 error_page: window.location.href || undefined,
//                 log_type: (type === 'error') ? '' : 'debug'
//             }
//
//             errorLogger(e_info)
//
//         }
//     }
// }

export const createHttp = (url:string) => {
    const axios = useNuxtApp().$axios
    const http = axios.create({
        baseURL: url || '',
        /*timeout: 3000*/
    })

    http.interceptors.request.use(
        config => {
            const token = getCookie('dj_xxer_hash=') || ''
            config.headers = {
                'dj-xxer-hash': token
            }

            const testMode = getCookie('dj_tmod') || ''
            const pipedriveMode = getCookie('dj_pmod') || ''
            if (testMode) {
                config.headers['dj-test-mode'] = testMode
            }

            if (pipedriveMode) {
                config.headers['dj-pipedrive-mode'] = pipedriveMode
            }

            config.meta = config.meta || {}
            config.meta.requestStartedAt = new Date().getTime()

            return config
        },
        error => {
            // errorHandle('error',error)
            return Promise.reject(error)
        })

    http.interceptors.response.use(function (response) {
            // errorHandle('response',response)
            return response;
        }, (error) => {
            // console.log('error ',error.toJSON())
            // errorHandle('error',error)
            return Promise.reject(error?.response?.data || error)
        }
    )

    return http
}

export const travelExplorerHttp = (url:string, includeHeader = true) => {
    const axios = useNuxtApp().$axios
    const csrf_token = window.csrf_token || ''
    const http = axios.create({
        baseURL: url || '',
        // timeout: 3000,
        headers: includeHeader ? {
            'csrf-token': csrf_token
        } : {},
    })

    http.interceptors.request.use(
        config => {
            const token = getCookie('dj_xxer_hash=') || ''
            config.headers = includeHeader ? {
                'dj-xxer-hash': token
            } : {}

            const testMode = getCookie('dj_tmod') || ''
            const pipedriveMode = getCookie('dj_pmod') || ''
            if (testMode) {
                config.headers['dj-test-mode'] = testMode
            }

            if (pipedriveMode) {
                config.headers['dj-pipedrive-mode'] = pipedriveMode
            }

            config.meta = config.meta || {}
            config.meta.requestStartedAt = new Date().getTime()

            return config
        },
        error => {
            // errorHandle('error',error)
            return Promise.reject(error)
        })

    http.interceptors.response.use(function (response) {
        // errorHandle('response',response)
        return response;
    }, error => {
        // errorHandle('error',error)
        return Promise.reject(error?.response?.data || error);
    })

    return http
}

export const travelExplorerAuthenHttp = (url:string) => {
    const axios = useNuxtApp().$axios
    const csrf_token = window.csrf_token || ''
    // const hash_token = getCookie('dj_xxer_hash=') || ''
    const http = axios.create({
        baseURL: url || '',
        // timeout: 3000,
        headers: {
            'csrf-token': csrf_token,
            // 'dj-xxer-hash': hash_token
        },
    })

    http.interceptors.request.use(
        config => {
            const token = getCookie('dj_xxer_hash=') || ''
            config.headers = {
                'dj-xxer-hash': token
            }

            const testMode = getCookie('dj_tmod') || ''
            const pipedriveMode = getCookie('dj_pmod') || ''
            if (testMode) {
                config.headers['dj-test-mode'] = testMode
            }

            if (pipedriveMode) {
                config.headers['dj-pipedrive-mode'] = pipedriveMode
            }

            config.meta = config.meta || {}
            config.meta.requestStartedAt = new Date().getTime()

            return config
        },
        error => {
            // errorHandle('error',error)
            return Promise.reject(error)
        })

    http.interceptors.response.use(function (response) {
        // errorHandle('response',response)
        return response;
    }, error => {
        // errorHandle('error',error)
        return Promise.reject(error?.response?.data || error);
    })

    return http
}
