import { defineStore } from 'pinia'
import homeApi from '~/composables/api/homeApi'

export const useGeneralInfoStore = defineStore('storeGeneralInfo', {
  state: () => ({
    generalData: {
      windowHeight: 0, //process.client ? window.innerHeight : 0,
      windowWidth: 1220, //process.client ? window.innerWidth : 768,
      megaMenuInfor: {
        status: false,
        itemSelected: {},
      },
      tabIndexSelected: 0,
      megaMenuInforMB: {
        status: false,
        statusSubNav: false,
        itemSelected: '',
      },
      specialDeals: [],
      appLoading: false,
      GTMTracking: {
        ClientID: '',
        GCLID: '',
        TrafficSource: '',
        TrafficMedium: '',
        TrafficCampaign: '',
        TrafficContent: '',
        TrafficKeyword: '',
        TrafficLandingPage: '',
      },
    },
    topMenuData: {},
    initChat: {
      status: false,
      token: '',
    },
    finishedChat: '',
    detectCountryLoaded: false,
    enquiryDetail: {
      showModalEnquiryDetail: false,
      enquiryDetailList: {}
    },
    detectCountryInfo: {},
    callDetect: false,
    enquirySubmitFormTA: false,
    carousel: {
      nextSlide: 0,
      preSlide: 0,
      listItems: []
    },
    clientRoute: '',
    customClassChat: "" as String,
    chatBoxOpenDone: {} as Object
  }),
  actions: {
    setWindowWidth() {
      this.generalData.windowWidth = process.client ? window.innerWidth : 768
      this.generalData.windowHeight = process.client ? window.innerHeight : 0
    },
    setWindowWidthSSR(data) {
      this.generalData.windowWidth = data
    },
    setMegaMenuStatus(data) {
      this.generalData.megaMenuInfor = data
    },
    setTabIndex(data) {
      this.generalData.tabIndexSelected = data
    },
    setMegaMenuStatusMB(data) {
      this.generalData.megaMenuInforMB = data
    },
    setTopMenu(data) {
      let new_list = []
      
      data.forEach((item) => {
        let name = item.title.toLowerCase()
        let obj = item
        obj.name = name
        new_list.push(obj)
      })
      
      this.topMenuData = new_list
    },
    setInitChat(response) {
      this.initChat = response
    },
    setFinishedChat(response) {
      this.finishedChat = response
    },
    setSpecialDeals(data) {
      this.generalData.specialDeals = data
    },
    setGTMTracking(data) {
      this.generalData.GTMTracking = data
    },
    setLoading(data) {
      this.generalData.appLoading = data
    },
    setDetectCountryLoaded(data) {
      this.detectCountryLoaded = data
    },
    setEnquiryDetail(data) {
      this.enquiryDetail = data
    },
    setDetectCountryInfo(data) {
      this.detectCountryInfo = data
    },
    setCallDetect(data) {
      this.callDetect = data
    },
    setEnquirySubmitFormTA(data) {
      this.enquirySubmitFormTA = data
    },
    setCarouselNextSlide(data) {
      this.carousel.nextSlide = data
    },
    setCarouselPreSlide(data) {
      this.carousel.preSlide = data
    },
    setCarouselData(data) {
      this.carousel.listItems = data
    },
    setClientRoute(data: String) {
      this.clientRoute = data
    },
    setChatBoxOpenDone(data: Object) {
      this.chatBoxOpenDone = data
    },
    // initChat(request) {
      // context.commit('setInitChat', request)
      // this.setInitChat(request)
    // },
    // finishedChat(request) {
      // context.commit('setFinishedChat', request)
      // this.setFinishedChat(request)
    // },
    async fetchCarouselData() {
      const getApiSearchHome = (await homeApi.getHomeSearchSettings()).data.value
      const durations = getApiSearchHome?.data?.durations
      const travel_themes = getApiSearchHome?.data?.travel_themes
      const response = durations.concat(travel_themes)
      // context.commit('setCarouselData', response)
      this.setCarouselData(response)
      return response
    },
    setCustomClassChat(data: String) {
      this.customClassChat = data
    }
  },
})
