export const gtmScript = (id) => {
  document.body.insertAdjacentHTML('afterbegin', `
    <!-- Google Tag Manager (noscript) -->
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${id}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <!-- End Google Tag Manager (noscript) -->
  `)
}

export const gtmScriptHead = (id) => {
  let gtmHeadScript = document.createElement('script')
  gtmHeadScript.append(`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${id}');`)
  document.head.appendChild(gtmHeadScript)
}

export const gtmTracking = () => {
  const GTMTrackingForm = document.getElementById('gtm-tracking-form')
  const data = {
    ClientID: GTMTrackingForm?.querySelector('#hiddenClientID').value || '',
    AffID: GTMTrackingForm?.querySelector('#hiddenAffID').value || '',
    GCLID: GTMTrackingForm?.querySelector('#hiddenGCLID').value || '',
    AdGroupID: GTMTrackingForm?.querySelector('#hiddenAdGroupID').value || '',
    TrafficSource: GTMTrackingForm?.querySelector('#hiddenTrafficSource').value || '',
    TrafficMedium: GTMTrackingForm?.querySelector('#hiddenTrafficMedium').value || '',
    TrafficCampaign: GTMTrackingForm?.querySelector('#hiddenTrafficCampaign').value || '',
    TrafficContent: GTMTrackingForm?.querySelector('#hiddenTrafficContent').value || '',
    TrafficKeyword: GTMTrackingForm?.querySelector('#hiddenTrafficKeyword').value || '',
    TrafficLandingPage: GTMTrackingForm?.querySelector('#hiddenTrafficLandingPage').value || ''
  }
  return data
}
