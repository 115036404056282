//const tripURL = `/rest/v1/retail`
const contentServiceURL = `/integration/v1`
const travelExplorerURL = `/extranet/v2`
const travelExplorerURLV3 = `/extranet/v3`
// const travelExplorerDomain =  `${process.env.VUE_APP_PUBLIC_DOMAIN_PUBLIC}`
const travelExplorerAuthenURL = `/authenticate`
const onlineStatusLDURL = '/chat-service/v2/user/status'
const requestChatURL = `/extranet/v2/chat/requestChat`
const callbackChatURL = `/extranet/v2/chat/callbackChat`

export {/*tripURL,*/
    contentServiceURL,
    travelExplorerURL,
    /*travelExplorerDomain,*/
    travelExplorerAuthenURL,
    onlineStatusLDURL,
    requestChatURL,
    callbackChatURL,
    travelExplorerURLV3
}
