import { contentServiceURL } from '~/composables/api/apiDomain'
import { useFetchPublicApi } from '~/composables/fetch/fetchComposables'

export default {
  getTopMenu() {
    return useFetchPublicApi.get(`${contentServiceURL}/get-menu-items`, {}, {
      baseURL: process.server ? useRuntimeConfig().localProductContentUrl : useAppConfig().baseApiUrl
    })
  },
}
