import {defineStore} from 'pinia'
import { debounceHeapTracking } from '~/mixins/heap'

export const useHeapStore = defineStore('storeHeap',{
  state: () => ({
    source_widget: '',
    LDProfile: {
      source_widget: "external_link"
    },
    Gallery: {
      source_widget: "external_link"
    },
    chatWindow: {
      data: {
        local_designer_chat_status: '',
        last_seen_at: '',
        enquiry: '',
        local_designer: '',
        trip_plan_url: '',
        destination: '',
        type: '',
        enquiry_status: '',
        number_of_unread_message: '',
      },
      shouldCallHeap: false
    }
  }),
  getters: {
    getLDProfileHeap: (state) => state.LDProfile,
    getGalleryHeap: (state) => state.Gallery,
    getChatWindowHeap: (state) => state.chatWindow
  },
  actions: {
    setLDProfileSource(response){
      this.LDProfile = { source_widget: response }
    },
    setGallerySource(response){
      this.Gallery = { source_widget: response }
    },
    setChatWindowHeapTrackStatus(response){
      this.chatWindow.shouldCallHeap = response
    },
    setChatWindowHeapData(response){
      this.chatWindow.data = { ...this.chatWindow.data, ...response }
    },
    changeLDProfileHeap(data) {
      this.setLDProfileSource(data)
    },
    changeGalleryHeap(data) {
      this.setGallerySource(data)
    },
    changeChatWindowHeapData(data) {
      this.setChatWindowHeapData(data)
    },
    setTrackChatWindow(data) {
      this.setChatWindowHeapTrackStatus(data)
    },
    heapTrackingChatWindow(data) {
      if (this.chatWindow.shouldCallHeap) {
        debounceHeapTracking('View Chat Window', { ...this.chatWindow.data, ...data })
      }
      return
    },
    heapTrackingMinimizeChat() {
      debounceHeapTracking('Minimize Chat Window')
    },
    heapTrackingMakePhoneCall(source_widget) {
      debounceHeapTracking('Make Phone Call', { source_widget })
    },
    heapTrackingViewListChat(data) {
      debounceHeapTracking('View All Chats', data)
    },
    heapTrackingErrorCaptured(data) {
      debounceHeapTracking('Error Captured', data)
    }
  }
})
