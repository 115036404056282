export default defineNuxtPlugin(nuxtApp => {
    nuxtApp.hook('app:created', (vueApp) => {
        //useRuntimeConfig().public.app_created = true
        //console.log('---------app:created hook-------')
    })

    nuxtApp.hook('vue:setup', () => {
        //console.log('vue:setup hook')
        //useRuntimeConfig().public.vue_setup = true
    })
})
