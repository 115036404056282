import { defineStore } from 'pinia'
import authApi from '~/composables/api/authApi'
import { keyLocalStorage } from '~/enum/enquiry'
import type {
  AgentPrivateRequestPayload,
  AgentRequestPayload,
  AuthRequestPayload,
  AuthUser,
  ForgotRequestPayload,
  RegisterRequestPayload,
} from '~/models/user.model'

export const useAuthStore = defineStore('storeAuth', {
  state: () => ({
    auth: {
      avatar: null,
      company_id: 0,
      email: '',
      firstName: '',
      hash: null,
      lastName: '',
      phone: '',
      timezone: '',
      token: null,
      userId: null,
      userType: null,
      user_id: null,
      error: 0,
      message: '',
      status: null,
      countryCode: '',
      is_valid: 0,
      is_customer: 0,
      currencyCode: '',
    } as AuthUser & {
      error: string | number
      message: string
      status: string | null
      is_valid: 0 | 1
      is_customer: 0 | 1
    },
  }),
  getters: {
    getUserInfo: (state) => state.auth,
  },
  actions: {
    async login(request: AuthRequestPayload) {
      const response = await authApi.login(request)
      this.setLogin(response.data)

      return response.data
    },

    async signUp(request: RegisterRequestPayload) {
      const response = await authApi.signUp(request)
      this.setSignUp(response.data)

      return response.data
    },

    async logout() {
      const response = await authApi.logout()
      this.setLogout(response.data)
      sessionStorage.removeItem(keyLocalStorage.enquiry)

      return response.data
    },

    async verify() {
      const response = await authApi.verify()
      this.setVerify(response?.data?.value || response?.error?.value?.data)
    },

    async signUpAgent(request: AgentRequestPayload) {
      const response = await authApi.signUpAgent(request)
      this.setSignUpAgent(response.data)

      return response.data
    },

    async signUpAgentPrivate(request: AgentPrivateRequestPayload) {
      const response = await authApi.signUpAgentPrivate(request)
      this.setSignUpAgent(response.data)

      return response.data
    },

    async forgotPassword(request: ForgotRequestPayload) {
      const response = await authApi.forgotPassword(request)
      this.setForgotPassword(response.data)

      return response.data
    },

    async checkCompany(request: { email: string }) {
      const response = await authApi.checkCompany(request)
      this.auth.company_id = response.data.company_id

      return response.data
    },

    async verifyEmail(request: { email: string; source: 'signup_agent' | 'enquiry_multiStep' | 'chat' }) {
      const response = await authApi.verifyEmail(request)
      this.setVerifyEmail(response.data)

      return response.data
    },

    setLogin(response: any) {
      this.auth.hash = response && response.data ? response.data?.hash : null
      this.auth.userType = response && response.data ? response.data?.user_type : null
      this.auth.error = response.error
      this.auth.message = response.message
      this.auth.userId = response && response.data ? response.data?.user_id : null
      this.auth.user_id = response && response.data ? response.data?.user_id : null
    },
    setSignUp(response: any) {
      this.auth.hash = response && response.data ? response.data?.hash : null
      this.auth.userType = response && response.data ? response.data?.user_type : null
      this.auth.error = response.error
      this.auth.message = response.message
      this.auth.userId = response && response.data ? response.data?.user_id : null
      this.auth.user_id = response && response.data ? response.data?.user_id : null
    },
    setLogout(response: any) {
      this.auth.error = response.error
      this.auth.message = response.message
    },
    setVerify(response: any) {
      this.auth.avatar = response?.data?.avatar || null
      this.auth.hash = response?.data?.hash || null
      this.auth.token = response?.data?.token || null
      this.auth.userType = response?.data?.user_type || null
      this.auth.timezone = response?.data?.timezone || ''
      this.auth.userId = response?.data?.user_id || null
      this.auth.user_id = response?.data?.user_id || null
      this.auth.firstName = response?.data?.first_name || ''
      this.auth.lastName = response?.data?.last_name || ''
      this.auth.email = response?.data?.email || ''
      this.auth.error = response?.error || 0
      this.auth.message = response?.message || ''
      this.auth.status = response?.status || null
      this.auth.phone = response?.data?.phone || ''
      this.auth.countryCode = response?.data?.country_code || ''
      this.auth.currencyCode = response?.data?.currency_code || ''
    },
    setSignUpAgent(response: any) {
      this.auth.hash = response && response.data ? response.data.hash : null
      this.auth.userType = response && response.data ? response.data.user_type : null
      this.auth.error = response.error
      this.auth.message = response.message
    },
    setForgotPassword(response: any) {
      this.auth.error = response.error
      this.auth.message = response.message
    },
    setVerifyEmail(response: any) {
      this.auth.error = response.error
      if (response.error === 0) {
        this.auth.is_valid = response.data.is_valid
        this.auth.is_customer = response.data.is_customer
      } else {
        this.auth.message = response.message
      }
    },
    setUserChatLogin(response: any) {
      this.auth = response
    },
  },
})
