import { useGetCookie } from "~/composables/common/useCustomCookie";

export default defineNuxtRouteMiddleware((to, from) => {
    if(!process.client) return;

    const token = useGetCookie("is_verified").value
    const trip_id = sessionStorage.getItem("trip_id")

    // console.log(to,' = RouteMiddleware = ',from, ' == ', token,' == ', trip_id)
    /* Check checkout need to link from bookable trip plan only */
    if (to.matched.some((record) => record.meta.checkout) && token) {
        // console.log(to,' = RouteMiddleware checkout = ',from)
        if(from.name !== 'trip-plan-bookable' && !trip_id) {
            return navigateTo({ name: 'home'})
        }
    }

    /* Check redirect if authenticated */
    if (to.matched.some((record) => record.meta.requiresAuth) && !token) {
        sessionStorage.setItem("auth_path", to.path)
        return navigateTo({ name: 'login-traveller' })
    } else if (token) {
        const routeName = ['login-traveller', 'signup-traveller','forgot-password']
        if(routeName.indexOf(to.name) !== -1) return navigateTo({ name: 'home' })
    }
})
