import {createConsola} from 'consola/browser';
import { debounce } from '~/helper';
//@ts-ignore
import {initWorker} from '@dj/logger-js';

/**
 * Initialize a logger with an optional tag.
 *
 * @param {string} tag - The optional tag for the logger
 * @param {object} options - The options object for creating the logger
 * @return {object} The initialized logger
 */

function initLogger(tag: string, options = {}) {
    const logger = createConsola(options);
    return tag ? logger.withTag(tag) : logger;
}

let rebuildLogWorker = false;

const loadWorkerScript = async (url: string) => {
    const f = await fetch(url);
    const t = await f.text();
    const b = new Blob([t], {
        type: 'application/javascript',
    });
    const wLib= URL.createObjectURL(b);
    return URL.createObjectURL(new Blob([`import {initLogger} from '${wLib}'; initLogger('');`], {
        type: 'application/javascript',
    }));
}

/**
 * Generates a new instance of LogWorker and sets up logging functionality.
 *
 * @return {Object} An object with functions to set the log type, timer, add log messages, and finish logging.
 */
export const useLogWorker = async () => {
    let instance = null;
    const {appEnvType, logWorkerSRC, logAPI, baseApiUrl, staticCDN, cdnSuffix} = useAppConfig();
    let script_uri = '/js/logger/esm/logger.worker.min.mjs';
    if (appEnvType !== 'local') {
        script_uri = `${staticCDN}${cdnSuffix}${script_uri}`;
    }
    try {
        const urlWorkerSrc = await loadWorkerScript(script_uri);
        const LogWorker = await initWorker(urlWorkerSrc);
        //@ts-ignore
        instance = await new LogWorker(`${baseApiUrl}${logAPI}`);
        await (await instance).start(0);

    } catch (e) {
        console.log('useLogWorker error >> ', e)
    }
    return new Promise(async (resolve) => {
        resolve({
            setLogType: async (type: any) => {
                if(instance) await (await instance).setType(type);
            },
            setLogTimer: async (timeout = 0) => {
                if(instance) await (await instance).start(timeout);
            },
            addLogMessage: async (message: any) => {
                if(instance) await (await instance).add(message);
            },
            finishLog: async (level = '', sendToServer = false) => {
                if(instance) await (await instance).finish(level, sendToServer);
            }
        });
    });
}
/**
 * Stringifies an object, converting Error objects to a serializable format
 * that can be safely passed to the web worker.
 *
 * @param {any} obj - the object to stringify
 * @return {string} the serialized object
 */
const toStringify = (obj: any) => {
    // If the object is an instance of Error,
    // convert it to a serializable format
    if (obj instanceof Error) {
        // Return the stringified object directly
        // without creating a new object
        return JSON.stringify({
            // Assign the error message as the 'msg' property
            msg: obj.message,
            // Assign the stack trace as the 'stack' property
            stack: obj.stack
        });
    }

    // Return the JSON.stringified object
    return JSON.stringify(obj);
}
const errorCause = (err: any) => {
    if (err instanceof Error && err.cause) {
        let {cause} = err;
        const causes=[];
        if(cause instanceof Array){
            for(const e of cause){
                causes.push(toStringify(e));
            }
            cause =causes;
        }
        return cause;
    }
    return '';
}
/**
 * A function that initializes and returns a logger based on the provided configuration and event.
 *
 * @param {any} config - the configuration object for the logger
 * @param {any} event - the event object for the logger
 * @return {Promise<any>} a Promise that resolves to the initialized logger
 */
export const getLogger = async (config: any, event: any) => {
    const logger = initLogger(config.graylogFacility || 'travel-exp-nuxt-csr');
    // custom logger with web worker
    if (!rebuildLogWorker){
        rebuildLogWorker = true;
        useLogWorker().then((worker: any) => {
            if(!worker)return;
            const submitLog = debounce(worker.finishLog, 10000);
            logger.addReporter({
                log: (logObj: any) => {
                    let {args} = logObj;
                    const errors = args.filter((err: any)=>(err instanceof Error));
                    const extras = args.filter((item: any)=>!(item instanceof Error)).map((item: any) => toStringify(item));                    
                    if(errors.length > 0){                        
                        worker.setLogType('error');
                        try {
                            const curl = useRequestURL();
                            if(extras.length > 0){
                                worker.addLogMessage({error_message: `Error ON ${curl} [${errors[0]?.message}]`, error_data: {
                                    error_data: extras,
                                    error_stack: errors[0]?.stack,
                                    error_cause: errorCause(errors[0])
                                }});
                            }else{
                                worker.addLogMessage(`Error ON ${curl} [${errors[0]?.message}]`);
                            } 
                            if(errors.length ==1){
                                submitLog('error', true);
                                return;
                            }                           
                        }catch (e) {}
                        errors.map((err: any) =>{
                            worker.addLogMessage(err?.stack);
                            if(err?.cause){
                                worker.addLogMessage(`Cause: ${toStringify(errorCause(err))}`);                                
                            }
                        });
                        
                        submitLog('error', true);
                    }
                }
            });
        }).catch(err=>logger.error(err));
    }
    return logger;
}

