import { useCookie } from 'nuxt/app'
import { COOKIE } from '~/enum/cookie.enum'

export const useGetCookie = (name: string, duration: number = COOKIE.CACHE_DURATION) => {
  let expires = new Date()
  if (duration) {
    let date = new Date()
    date.setTime(date.getTime() + duration * 24 * 60 * 60 * 1000)
    expires = date
  }
  return useCookie(name, expires? { expires: expires }: {})
}
