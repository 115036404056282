import { useFetch, useRuntimeConfig, type UseFetchOptions } from 'nuxt/app'
export interface ResOptions<T> {
  data?: T
  errors?: number
  message?: string
  success?: boolean
}

type UrlType = string | Request | Ref<string | Request> | (() => string | Request)
export type HttpOption<T> = UseFetchOptions<ResOptions<T>>

export const fetchApi = async <T>(url: UrlType, httpOptions: HttpOption<T> = {}, includeHeader: boolean = true) => {
  /*const {
    public: { apiDomain },
  } = useRuntimeConfig()*/

  const token: string | Ref<string> | undefined = useCookie('dj_xxer_hash')
  const testMode: string | Ref<string> | undefined = useCookie('dj_tmod')
  const pipedriveMode: string | Ref<string> | undefined = useCookie('dj_pmod')
  // const requestStartedAt: number = new Date().getTime()
  // const requestStartedAt: { cookie?: string } = useRequestHeaders(['cookie'])
  // const csrf_token: string | Ref<string> | undefined = useCookie('csrf_token')
  let cParams = {...httpOptions}
  delete cParams.baseURL
  //delete httpOptions.baseURL
  const fParams = JSON.stringify(cParams);
  const fKey = `apis:${httpOptions.method ?? 'get'}:${url}:${fParams}`;
  const defaults: HttpOption<T> = {
    baseURL: useAppConfig().baseApiUrl,
    key: fKey,
    timeout: 30000,
    //baseURL: `${process.server ? useRuntimeConfig().localProductContentUrl : useAppConfig().baseApiUrl}`,
    headers: {
      'dj-xxer-hash': token.value ? token.value : '',
      'dj-test-mode': testMode.value ? testMode.value : '',
      'dj-pipedrive-mode': pipedriveMode.value ? pipedriveMode.value : '',
      // 'requestStartedAt': requestStartedAt // String(requestStartedAt)
      // 'csrf_token': !includeHeader ? '' : csrf_token.value ? csrf_token.value : ''
    },
    onResponse({ request, response, options }) {
      // console.log('[fetchApi onResponse] ', request,' == ',response._data,' == ', options)
    },
    onResponseError({ request, response, options }) {
      // Handle the response errors
      // console.log('[fetchApi onResponseError] ', request,' == ',response._data,' == ', options)
    },
    onRequest({ request, options }) {
      options.headers = options.headers || {}
      // if(process.server) {
      //   console.log('[fetchApi onRequest 2]', process, request,' == ', options)
      // }

      // Set the request headers
      // options.headers = options.headers || {}
      // options.headers.authorization = '...'
      // console.log(/*token.value,*/ '[fetchApi onRequest] ', request/*, ' == ', options*/)
    },
    // onRequestError({ request, options, error }) {
      // Handle the request errors
      //console.log('[fetchApi onRequestError] ',request, ' == ',options,' == ',error)
    // },
  }

  return await useFetch(url, { ...defaults, ...httpOptions })
}

export const useFetchApi = {
  get: async <T>(url: UrlType, query?: any, option?: HttpOption<T>) => {
    return await fetchApi<T>(url, { method: 'get', query, ...option })
  },
  post: async <T>(url: UrlType, body?: any, option?: HttpOption<T>) => {
    return await fetchApi<T>(url, { method: 'post', body, ...option })
  },
  put: async <T>(url: UrlType, body?: any, option?: HttpOption<T>) => {
    return await fetchApi<T>(url, { method: 'put', body, ...option })
  },
  delete: async <T>(url: UrlType, body?: any, option?: HttpOption<T>) => {
    return await fetchApi<T>(url, { method: 'delete', body, ...option })
  },
}

export const useFetchPublicApi = {
  get: async <T>(url: string, query?: any, option?: HttpOption<T>) => {
    return await fetchApi<T>(url, { method: 'get', query, ...option }, false)
  },
  post: async <T>(url: string, body?: any, option?: HttpOption<T>) => {
    return await fetchApi<T>(url, { method: 'post', body, ...option }, false)
  },
  put: async <T>(url: string, body?: any, option?: HttpOption<T>) => {
    return await fetchApi<T>(url, { method: 'put', body, ...option }, false)
  },
  delete: async <T>(url: string, body?: any, option?: HttpOption<T>) => {
    return await fetchApi<T>(url, { method: 'delete', body, ...option }, false)
  },
}
