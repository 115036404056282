import {travelExplorerURL} from './apiDomain'
import {travelExplorerHttp} from './http'

// let client = travelExplorerHttp(travelExplorerURL)
// let clientTE = travelExplorerHttp(null, false)

export default {
    groupSubmitEnquiry(params) {
        const client = travelExplorerHttp(travelExplorerURL)
        return client.post('/group-enquiry', params)
    },
    agentSubmitEnquiry(params) {
        const client = travelExplorerHttp(travelExplorerURL)
        return client.post('/agent-enquiry', params)
    },
    uploadFile(file) {
        const client = travelExplorerHttp(travelExplorerURL)
        return client.post('/enquiry/upload-file', file)
    },
    uploadFileNewEndpoint(file) {
        const clientTE = travelExplorerHttp('', false)
        return clientTE.post('/api/upload/v1/uploadMedia', file)
    },
    enquiryDetail(id) {
        const client = travelExplorerHttp(travelExplorerURL)
        return client.get(`/enquiries/${id}`)
    },
    getListEnquiries(params) {
        const client = travelExplorerHttp(travelExplorerURL)
        return client.get('/enquiries', { params: params })
    },
    updateEnquiryDuration(id, data) {
        const client = travelExplorerHttp(travelExplorerURL)
        return client.post(`/enquiries/${id}/update-dates`, data)
    }
}
