import { useFetchApi } from '../fetch/fetchComposables';
import {travelExplorerURL, travelExplorerAuthenURL} from './apiDomain'
import {travelExplorerHttp, travelExplorerAuthenHttp} from './http'

// let client = travelExplorerHttp(travelExplorerURL);
// let authen = travelExplorerAuthenHttp(travelExplorerAuthenURL);

export default {
    login(data) {
        const client = travelExplorerHttp(travelExplorerURL);
        return client.post('/user/login', data)
    },

    signUp(data) {
        const client = travelExplorerHttp(travelExplorerURL);
        return client.post('/user/sign-up', data)
    },

    logout() {
        const authen = travelExplorerAuthenHttp(travelExplorerAuthenURL);
        return authen.get('/logout')
    },

    verify() {
        return useFetchApi.get(`${travelExplorerAuthenURL}/verify`, {}, {
            baseURL: process.server ? useRuntimeConfig().localTravelExplorerUrl : useAppConfig().baseApiUrl
        })
        /*const authen = travelExplorerAuthenHttp(travelExplorerAuthenURL);
        return authen.get('/verify')*/
    },

    signUpAgent(data) {
        const client = travelExplorerHttp(travelExplorerURL);
        return client.post('/user/sign-up-travel-agent', data)
    },
    
    signUpAgentPrivate(data) {
        const client = travelExplorerHttp(travelExplorerURL);
        return client.post('/user/sign-up-travel-agent-private', data)
    },

    forgotPassword(data) {
        const client = travelExplorerHttp(travelExplorerURL);
        return client.post('/user/forgot-password', data)
    },

    checkCompany(data) {
        const client = travelExplorerHttp(travelExplorerURL);
        return client.post('/check-company', data)
    },

    verifyEmail(data){
        const client = travelExplorerHttp(travelExplorerURL);
        return client.post('/verify-email', data)
    },

    signUpLocalDesigner(data){
        const client = travelExplorerHttp(travelExplorerURL);
        return client.post('/user/sign-up-local-designer', data)
    },

    getUserInfo(id: number | string) {
        return useFetchApi.get(`${travelExplorerURL}/user/details/${id}`, {}, {
            baseURL: process.server ? useRuntimeConfig().localTravelExplorerUrl : useAppConfig().baseApiUrl
        })

    },
    getResendEmail(params) {
        const client = travelExplorerHttp(travelExplorerURL);
        return client.get(`/user/resend-confirm`,  { params: params })
    },
    createPassword(data) {
        const client = travelExplorerHttp(travelExplorerURL);
        return client.post('/user/reset-password', data)
    },
    setPasswordAgent(data) {
        const client = travelExplorerHttp(travelExplorerURL);
        return client.post('/user/set-password-agent', data)
    }
}
