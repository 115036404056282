<template>
  <ClientOnly>
    <Teleport :to="teleportTarget">
      <transition name="loading">
        <div v-if="isActive" id="loading-bg" class="z-9999">
          <div class="loader">
            <!-- <div class="effect-1 effects" />
            <div class="effect-2 effects" />
            <div class="effect-3 effects" /> -->
          </div>
        </div>
      </transition>
    </Teleport>
    <template #fallback>
      <div style='display: none; font-size: 0; line-height: 0; height: 1px'>Loading</div>
    </template>
  </ClientOnly>

</template>
<script>
import { ref, watch, computed } from 'vue'
import { useGeneralInfoStore } from '~/store/pinia/generalInfo'
export default {
  name: 'AppLoading',
  props: {
    teleportTarget: {
      type: String,
      default: 'body',
    },
  },
  setup(props) {
    const nuxtApp = useNuxtApp()
    const ROUTE_LOADING = [
      'trip-gallery',
      'types-gallery',
      'enquiry-submit-global',
      'enquiry-submit-destination',
      'enquiry-submit-trip',
      'enquiry-submit-ld',
      'trip-plan-bookable',
      'trip-plan',
      'country-travel-guide',
    ]
    const routeTo = ref(null)
    const routeFrom = ref(null)
    const savedPosition = useState('savedPosition')
    const generalStore = useGeneralInfoStore()
    
    const isActive = computed(() => generalStore.generalData.appLoading)
    const close = () => {
      generalStore.setLoading(false)
    }

    const offsetY = ref(0)
    watch(
      () => props.modalActive,
      (value) => {
        if (value) {
          offsetY.value = window.pageYOffset
          document.body.style.position = 'fixed'
          document.body.style.width = '100%'
          document.body.style.top = -offsetY.value + 'px'
        } else {
          document.documentElement.style.scrollBehavior = 'auto'
          document.body.style.position = 'static'
          window.scrollTo({
            top: offsetY.value,
            behavior: 'auto',
          })
          document.documentElement.style.scrollBehavior = 'smooth'
        }
      },
    )

    // Add middle ware
    addRouteMiddleware(
      'behavior-loading',
      (to, from) => {
        const sameName = to.name === from.name
        routeTo.value = to
        routeFrom.value = from
        generalStore.setLoading(!sameName)
      },
      { global: true },
    )

    nuxtApp.hook('page:finish', async () => {
      if (ROUTE_LOADING.includes(routeTo.value?.name)) return
      // console.log(`page:finish`)
      await nextTick(() => {
        if (generalStore.generalData.appLoading) {
          generalStore.setLoading(false)
        }
      })
    })

    nuxtApp.hook('page:finish', () => {
      if (!routeTo.value) return
      if (routeTo.value.hash) {
        return window.scrollTo({ top: 0, behavior: 'instant' })
      } else if (savedPosition.value) {
        return window.scrollTo({ ...savedPosition.value, behavior: 'instant' })
      } else if (routeTo.value.matched.some(m => m.meta.onScroll)) {
        const isSameName = routeTo.value.name == routeFrom.value.name
        return !isSameName ? window.scrollTo({ top: 0, behavior: 'instant' }) : false
      } else {
        return window.scrollTo({ top: 0, behavior: 'instant' })
      }
    })

    nuxtApp.hook('vue:error', async (err, target, info) => {
      // console.log('vue:error app loading', { err, target, info })
      // close loading when error
      await nextTick(() => {
        if (generalStore.generalData.appLoading) {
          generalStore.setLoading(false)
        }
      })
    })

    return { close, isActive }
  },
}
</script>
<style lang="scss" scoped>
#loading-bg {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.75);
  display: block;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  width: 38px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #000 90%, #0000);
  background:
    var(--_g) 0% 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: loading 1s infinite linear;
}

@keyframes loading {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%
  }

  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%
  }

  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%
  }

  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%
  }
}

// .loading {
//   position: absolute;
//   left: calc(50% - 35px);
//   top: 50%;
//   width: 55px;
//   height: 55px;
//   border-radius: 50%;
//   box-sizing: border-box;
//   border: 3px solid transparent;
// }
// .loading .effect-1,
// .loading .effect-2 {
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   border: 3px solid transparent;
//   border-left: 3px solid $black;
//   border-radius: 50%;
//   box-sizing: border-box;
// }

// .loading .effect-1 {
//   animation: rotate 1s ease infinite;
// }
// .loading .effect-2 {
//   animation: rotateOpacity 1s ease infinite 0.1s;
// }
// .loading .effect-3 {
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   border: 3px solid transparent;
//   border-left: 3px solid $black;
//   -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
//   animation: rotateOpacity 1s ease infinite 0.2s;
//   border-radius: 50%;
//   -webkit-box-sizing: border-box;
//   box-sizing: border-box;
// }

// .loading .effects {
//   transition: all 0.3s ease;
// }

// @keyframes rotate {
//   0% {
//     -webkit-transform: rotate(0deg);
//     transform: rotate(0deg);
//   }
//   100% {
//     -webkit-transform: rotate(1turn);
//     transform: rotate(1turn);
//   }
// }
// @keyframes rotateOpacity {
//   0% {
//     -webkit-transform: rotate(0deg);
//     transform: rotate(0deg);
//     opacity: 0.1;
//   }
//   100% {
//     -webkit-transform: rotate(1turn);
//     transform: rotate(1turn);
//     opacity: 1;
//   }
// }
</style>
