import {defineStore} from 'pinia'

export const useCurrenciesStore = defineStore('storeCurrencies',{
  state: () => ({
    currenciesData: null as any,
    currencySelected: {
      code: "",
      symbol: "",
      decimal: ".",
      label: "",
      rate: 1,
      separate: ""
    },
    currencyChangeByUser: 0,
    tripBooked: false,
    loaded: false
  }),
  
  getters: {
    getCurrencySelected: (state) => {
      return state.currencySelected
    },
    getCurrenciesList: (state) => {
      return state.currenciesData
    }
  },
  actions: {
    setCurrenciesList(response) {
      this.currenciesData = response
    },
    setCurrencySelected(data: any) {
      this.currencySelected = data
    },
    setCurrencyDropdownClick(data) {
      this.currencyChangeByUser = data
    },
    setTripBooked(data) {
      this.tripBooked = data
    },
    setLoaded(data) {
      this.loaded = data
    }
  }
})
