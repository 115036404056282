export enum EnquiryOptionLevel {
  Country = 0,
  Region = 1,
  City = 1,
}

export enum EnquiryOptionType {
  Gallery = 'gallery',
  Country = 'country',
  Designer = 'designer',
}

export enum EnquiryStepEnum {
  Information = 'information',
  YourTrip = 'your_trip',
  How = 'how',
  YourStyle = 'your_style',
  YourBudget = 'your_budget',
}

export enum EnquiryStep {
  AboutYou = 1,
  YourTrip,
  TravellersAccommodation,
  YourStyle,
  EstimatedPrice,
  Completed,
}

export enum keyLocalStorage {
  enquiry = 'enquiry_capture',
  enquiryId = 'enquiry_capture_id',
}

export const ENQUIRY_STEP_BASE = [
  EnquiryStepEnum.Information,
  EnquiryStepEnum.YourTrip,
  EnquiryStepEnum.How,
  EnquiryStepEnum.YourStyle,
  EnquiryStepEnum.YourBudget,
]
export const ENQUIRY_STEP_TESTING_VARIANT = [
  EnquiryStepEnum.YourTrip,
  EnquiryStepEnum.How,
  EnquiryStepEnum.YourStyle,
  EnquiryStepEnum.Information,
  EnquiryStepEnum.YourBudget,
]

export const ArrayTextBottomEnquiry = [
  'Planning Your Trip Cost You Nothing',
  'Next: Your Style',
  'Next: Your Information',
  'Next: Estimated Price & Budget',
  'A detailed, tailored trip plan awaits'
]
