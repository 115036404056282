export const setCookie = (name, value, duration) => {
  if(!process.client) return;
  let expires = ""
  if (duration) {
    let date = new Date()
    date.setTime(date.getTime() + (duration*24*60*60*1000))
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/"
}

export const getCookie = (name) => {
  if(!process.client) return;
  const value = document.cookie.split('; ').find(row => row.startsWith(name))
  if (value) {
    let cookieValue = value.split('=')[1]
    return cookieValue
  }
  return null
}
