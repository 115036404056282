import { contentServiceURL, travelExplorerURL } from '~/composables/api/apiDomain'
import { useFetchPublicApi, useFetchApi } from '~/composables/fetch/fetchComposables'
import { travelExplorerHttp } from './http'

export default {
  getCurrencies() {
    return useFetchPublicApi.get(`${contentServiceURL}/currencies`)
  },
  postCurrency(code: any) {
    const clientTE = travelExplorerHttp(travelExplorerURL)
    return clientTE.post('/user/change-currency', { currency_code: code })
  },
  getDetectCountry(ip) {
    //let ipAddr = useCookie('remote_addr')
    return useFetchApi.get('/detectCountry', {remote_addr: process.server ? ip : window.client_addr}, {
      baseURL: process.server ? useRuntimeConfig().localUrl : useAppConfig().baseApiUrl
    })
  },
}
