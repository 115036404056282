export const STATIC_CURRENCIES = [
  {
    code: 'AUD',
    label: 'Australian Dollar',
    symbol: 'AU$',
  },
  {
    code: 'GBP',
    label: 'British Pound Sterling',
    symbol: '£',
  },
  {
    code: 'CAD',
    label: 'Canadian Dollar',
    symbol: 'CA$',
  },
  {
    code: 'EUR',
    label: 'Euro',
    symbol: '€',
  },
  {
    code: 'JPY',
    label: 'Japanese Yen',
    symbol: '¥',
  },
  {
    code: 'NZD',
    label: 'New Zealand Dollar',
    symbol: 'NZ$',
  },
  {
    code: 'USD',
    label: 'US Dollar',
    symbol: 'US$',
  },
]
