import { useFetchPublicApi } from '../fetch/fetchComposables';
import {contentServiceURL} from './apiDomain'
import {createHttp} from './http'

// let client = createHttp(contentServiceURL);

export default {
    getTrendingDes() {
        return useFetchPublicApi.get(`${contentServiceURL}/trending-destinations`, {}, {
            baseURL: process.server ? useRuntimeConfig().localProductContentUrl : useAppConfig().baseApiUrl
        })
    },
    getFreeCancellation(params) {
        const client = createHttp(contentServiceURL);
        return client.get('/trips/free-cancellation', {
            params,
        })
    },
    getHomeSearchSettings() {
        return useFetchPublicApi.get(`${contentServiceURL}/get-home-search-settings`)
    }
}
