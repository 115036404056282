export default defineNuxtPlugin(nuxtApp => {
    // nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
    //     console.log('nxt:error client',error,' == ',instance,' == ',info)
    // }
    //
    // nuxtApp.hook('vue:error', (..._args) => {
    //     //console.log('vue:error client', ..._args)
    //     if (process.client) {
    //         console.log(..._args)
    //     }
    // })
    //
    // nuxtApp.hook('app:error', (..._args) => {
    //     //console.log('app:error client')
    //     if (process.client) {
    //       console.log(..._args)
    //     }
    // })
    //
    // nuxtApp.vueApp.config.errorHandler = (..._args) => {
    //     //console.log('global error handler client')
    //     if (process.client) {
    //       console.log(..._args)
    //     }
    // }
})
