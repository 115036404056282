// import { publicDomain } from '@/helper'
import { debounce } from '~/helper'

export const heapScript = (id = null, heapID, scriptID) => {
  let script = `
          window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){window.heap && (typeof heap.push === 'function') && heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
          heap.load("${heapID}");
      `;
  if (id) {
    // console.log('heapScript', id)
    // id = id - 10000
    script += `heap.identify('${parseInt(id)}');`
  }

  let heapScript = document.createElement('script')
  heapScript.append(script)
  heapScript.setAttribute('type', 'text/javascript')
  heapScript.setAttribute('id', scriptID)
  document.head.appendChild(heapScript)
}

export const debounceHeapTracking = (eventName, customProps, time = 500) => {
  if (window.heap) {
    // console.log("debounceHeapTracking eventName: ",eventName)
    debounce(window.heap.track(eventName, customProps)
      , time)
  }
}

// export const newHeapScript = (id = null) => {
//   // const isProEnv = process.env.VUE_APP_ENV
//   const heap_id = process.env.VUE_APP_HEAP_ID_TA
//   // const heap_id = (isProEnv === 'production') ? '3109270657' : '554364170'
//   let heapScript = document.createElement('script')
//   let script = `
//         window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
//         heap.load("${heap_id}");
//       `;
//   if(id){
//     id = id - 10000
//     script += `heap.identify("${id}");`
//   }
//
//   heapScript.append(script)
//   heapScript.setAttribute('type', 'text/javascript')
//   heapScript.setAttribute('id', 'new_heap_script')
//   document.head.appendChild(heapScript)
// }
